<template>
	<div class="notice">
		<div style="display: flex;justify-content: space-between;">
			<div class="header">
				<div>裁判管理></div>
				<div class="current">注册查询</div>
			</div>
			<div style="display: flex;">
				<div class="buttom" @click="applyfor">
					<img class="img" src="../assets/image/registration.png" alt="">
					<div>裁判员注册</div>
				</div>
				<div class="buttomdow" @click="download">
					<div>裁判员注册操作手册</div>
				</div>
			</div>
		</div>

		<div class="nav">
			<div @click="getnav(1)" :class="navIndex == 1 ? 'select' : ''">裁判员证书全览</div>
			<div @click="getnav(2)" :class="navIndex == 2 ? 'select' : ''">裁判员全览</div>
		</div>
		<div class="xian"></div>



		<div v-if="navIndex == 1">
			<div class="searchfor">
				<div class="inp">
					<el-input placeholder="请输入姓名" class="name" v-model="info.refereeName">
						<template slot="prefix">
							<span class="searchforlogo" style="width: 22PX;height: 27PX;">
								<img src="../assets/image/name.png" alt="" srcset="">
							</span>
						</template>
					</el-input>
				</div>
				<el-select placeholder="请选择体育项目" class="project" v-model="info.sports" filterable multiple
					collapse-tags>
					<el-option v-for="item in options1" :key="item.itemValue" :label="item.itemText"
						:value="item.itemValue">
					</el-option>
					<template slot="prefix">
						<span class="searchforlogo">
							<img src="../assets/image/project.png" alt="" srcset="">
						</span>
					</template>
				</el-select>
				<el-select placeholder="请选择裁判级别" class="level" v-model="info.refereeLevel" multiple collapse-tags>
					<el-option v-for="item in options2" :key="item.itemValue" :label="item.itemText"
						:value="item.itemValue">
					</el-option>
					<template slot="prefix">
						<span class="searchforlogo" style="width: 20PX;height: 27PX;">
							<img src="../assets/image/level.png" alt="" srcset="">
						</span>
					</template>
				</el-select>
				<el-select placeholder="请选择注册所在区" class="area" v-model="info.certificateArea" multiple collapse-tags>
					<el-option v-for="item in options3" :key="item.itemValue" :label="item.itemText"
						:value="item.itemValue">
					</el-option>
					<template slot="prefix">
						<span class="searchforlogo">
							<img src="../assets/image/area.png" alt="" srcset="">
						</span>
					</template>
				</el-select>
				<div class="but" @click="searchfor">
					<div class="img">
						<img src="../assets/image/searchfor.png" alt="">
					</div>
					<div class="text">搜索</div>
				</div>
				<el-select placeholder="请选择状态" class="status" v-model="info.applyForStatus" multiple collapse-tags>
					<el-option v-for="item in options4" :key="item.itemValue" :label="item.itemText"
						:value="item.itemValue">
					</el-option>
					<template slot="prefix">
						<span class="searchforlogo">
							<img src="../assets/image/statuss.png" alt="" srcset="">
						</span>
					</template>
				</el-select>

				<el-date-picker style="padding: 0;width: 300PX;" placeholder="请选择审批年份" class="year" v-model="date"
					type="monthrange" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份"
					@change="getdate" format="yyyy-MM" value-format="yyyy-MM">
					<img slot="prefix" src="../assets/image/year.png" alt="" srcset="">
				</el-date-picker>

				<div class="but" @click="reset" style="margin-top: 19PX;">
					<div class="img">
						<img src="../assets/image/reset.png" alt="">
					</div>
					<div class="text">重置</div>
				</div>
			</div>

			<div class="piece">
				<div class="backck" v-for="(item, index) in data" :key="index" @click="getdetails(item)"
					v-if="data && data.length > 0">
					<div class="information">
						<div class="headsculpture">
							<img :src="item.image">
						</div>
						<div class="specific-box">
							<div class="specific">
								<div class="identifying"></div>
								<div class="definition">{{ item.refereeName }}</div>
								<img class="garden" src="../assets/image/garden.png" alt="">
							</div>
							<div class="step" style="margin-top: 22PX;">{{ item.sports_dictText }}</div>
							<div class="step" style="margin-top: 10PX;">{{ item.refereeLevel }}</div>
							<div class="step" style="margin-top: 10PX;" v-if="item.applyForStatus == 40">已注册</div>
							<div class="step" style="margin-top: 10PX;" v-if="item.applyForStatus == 60">未年度注册</div>
							<!-- <div class="step" style="margin-top: 10PX;" v-if="item.applyForStatus == '--'">--</div> -->
							<div class="step" style="margin-top: 10PX;" v-if="item.applyForStatus == '无需注册'">无需注册</div>
							<div class="step" style="margin-top: 10PX;">注册所在区：{{ item.certificateArea }}</div>
						</div>
					</div>
					<img src="../assets/image/coach.png" alt="">
				</div>
			</div>
		</div>

		<div v-if="navIndex == 2">
			<div class="searchfor">
				<div class="inp">
					<el-input placeholder="请输入姓名" class="name" v-model="info.refereeName">
						<template slot="prefix">
							<span class="searchforlogo" style="width: 22PX;height: 27PX;">
								<img src="../assets/image/name.png" alt="" srcset="">
							</span>
						</template>
					</el-input>
				</div>
				<el-select placeholder="请选择性别" class="project" v-model="info.refereeSex" multiple collapse-tags>
					<el-option v-for="item in options6" :key="item.itemValue" :label="item.itemText"
						:value="item.itemValue">
					</el-option>
					<template slot="prefix">
						<span class="searchforlogo" style="width: 31PX;height: 18PX;margin-top: 10PX;">
							<img src="../assets/image/genders.png" alt="" srcset="">
						</span>
					</template>
				</el-select>
				<el-select placeholder="请选择体育项目" class="project" v-model="info.sports" filterable multiple
					collapse-tags>
					<el-option v-for="item in options1" :key="item.itemValue" :label="item.itemText"
						:value="item.itemValue">
					</el-option>
					<template slot="prefix">
						<span class="searchforlogo">
							<img src="../assets/image/project.png" alt="" srcset="">
						</span>
					</template>
				</el-select>
				<el-select placeholder="请选择裁判级别" class="level" v-model="info.refereeLevel" multiple collapse-tags>
					<el-option v-for="item in options2" :key="item.itemValue" :label="item.itemText"
						:value="item.itemValue">
					</el-option>
					<template slot="prefix">
						<span class="searchforlogo" style="width: 20PX;height: 27PX;">
							<img src="../assets/image/level.png" alt="" srcset="">
						</span>
					</template>
				</el-select>
				<div class="but" @click="searchfor">
					<div class="img">
						<img src="../assets/image/searchfor.png" alt="">
					</div>
					<div class="text">搜索</div>
				</div>
				<el-select placeholder="请选择注册所在区" class="status" v-model="info.certificateArea" multiple collapse-tags>
					<el-option v-for="item in options3" :key="item.itemValue" :label="item.itemText"
						:value="item.itemValue">
					</el-option>
					<template slot="prefix">
						<span class="searchforlogo">
							<img src="../assets/image/area.png" alt="" srcset="">
						</span>
					</template>
				</el-select>
				<div class="but" @click="reset" style="margin-top: 19PX;">
					<div class="img">
						<img src="../assets/image/reset.png" alt="">
					</div>
					<div class="text">重置</div>
				</div>
			</div>

			<div class="piece">
				<div class="backck" v-for="(item, index) in data" :key="index" @click="getdetails(item)"
					v-if="data && data.length > 0">
					<div class="information">
						<div class="headsculpture">
							<img :src="item.image">
						</div>
						<div class="specific-box">
							<div class="specific">
								<div class="identifying" style="margin-top: 53PX;"></div>
								<div class="definition" style="margin-top: 50PX;">{{ item.refereeName }}</div>
								<img class="garden" src="../assets/image/garden.png" alt="" style="margin-top: 40PX;">
							</div>
						</div>
					</div>
					<img src="../assets/image/coach.png" alt="">
				</div>
			</div>
		</div>

		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :go="12" :pageSize="12" :refreshNum="refreshNum"
				:pageNo="info.pageNo"></pagination>
		</div>

	</div>
</template>
<script>
export default {
	name: 'notice',
	components: {},
	data() {
		return {
			options1: [],
			options2: [],
			options3: [],
			options4: [
				{
					itemText: '已注册',
					itemValue: '40'
				},
				{
					itemText: '未年度注册',
					itemValue: '60'
				},
				{
					itemText: '无需注册',
					itemValue: '无需注册'
				}
			],
			options6: [
				{
					itemText: '男',
					itemValue: 1
				},
				{
					itemText: '女',
					itemValue: 2
				}
			],
			info: {
				pageNo: 1,
				pageSize: 12,
				refereeLevel: null,
				certificateArea: null,
				sports: null,
				refereeName: null,
				refereeSex: null,
				applyForStatus: null,
				startDate: null,
				endDate: null,
			},
			date: [],
			total: 0,
			data: [],
			navIndex: 1,
			refreshNum: 1
		}
	},
	created() {
		this.sportEventList()
		this.judgmentLevel()
		this.areaList()
		this.getRefereeBase()
	},
	methods: {
		currentchange(val) {
			this.info.pageNo = val
			if (this.navIndex == 2) {
				this.selectAllRefereeUser()
			} else {
				this.getRefereeBase()
			}
		},
		searchfor() {
			this.info.pageNo = 1
			if (this.navIndex == 2) {
				this.selectAllRefereeUser()
			} else {
				this.getRefereeBase()
			}
		},
		reset() {
			this.info = {
				pageNo: 1,
				pageSize: 12,
				refereeLevel: null,
				certificateArea: null,
				sports: null,
				refereeName: null,
				refereeSex: null,
				applyForStatus: null,
				startDate: null,
				endDate: null,
			},
			this.date = []
			this.data = []
			this.total = 0
			if (this.navIndex == 2) {
				this.selectAllRefereeUser()
			} else {
				this.getRefereeBase()
			}
		},
		getRefereeBase() {
			const infoCopy = JSON.parse(JSON.stringify(this.info));
			if (!this.$verification(infoCopy.sports)) {
				infoCopy.sports = infoCopy.sports.join(",")
			} else {
				infoCopy.sports = ''
			}
			if (!this.$verification(infoCopy.applysportsForStatus)) {
				infoCopy.applysportsForStatus = infoCopy.applysportsForStatus.join(",")
			} else {
				infoCopy.applysportsForStatus = ''
			}
			if (!this.$verification(infoCopy.refereeLevel)) {
				infoCopy.refereeLevel = infoCopy.refereeLevel.join(",")
			} else {
				infoCopy.refereeLevel = ''
			}
			if (!this.$verification(infoCopy.certificateArea)) {
				infoCopy.certificateArea = infoCopy.certificateArea.join(",")
			} else {
				infoCopy.certificateArea = ''
			}
			if(!this.$verification(infoCopy.applyForStatus)){
				infoCopy.applyForStatus = infoCopy.applyForStatus.join(",")
			}else{
				infoCopy.applyForStatus = ''
			}
			if (!this.$verification(infoCopy.refereeSex)) {
				infoCopy.refereeSex = infoCopy.refereeSex.join(",")
			} else {
				infoCopy.refereeSex = ''
			}
			this.$api.getRefereeBase(infoCopy).then(res => {
				res.data.data.result.records.forEach(item => {
					for (let i in this.options3) {
						if (item.certificateArea == this.options3[i].itemValue) {
							item.certificateArea = this.options3[i].itemText
						}
					}
					for (let i in this.options2) {
						if (item.refereeLevel == this.options2[i].itemValue) {
							item.refereeLevel = this.options2[i].itemText
						}
					}
					if (item.avatar) {
						this.$api.getFile({
							objectName: item.avatar
						}).then(ess => {
							this.$convert(ess.data.data.result.url).then((fileUrl) => {
								item.image = fileUrl
								this.$forceUpdate()
							})
						})
					} else {
						item.image = require('@/assets/image/defaults.png')
						this.$forceUpdate()
					}
				})

				res.data.data.result.records.forEach(item => {
					item.refereeName = this.$decrypt(item.refereeName || '')
				})

				if (this.navIndex == 1) {
					this.data = res.data.data.result.records
					this.total = res.data.data.result.total
				}
			})
		},
		getdetails(row) {
			if (this.navIndex == 1) {
				this.$router.push({
					path: '/refereeDetails',
					query: {
						sports: row.sports,
						id: row.refereeId,
						refresh: new Date().getTime(),
						columnId: this.$route.query.id,
						columnPid: this.$route.query.pid,
					}
				});
			} else {
				this.$router.push({
					path: '/refereeDetailss',
					query: {
						sports: row.sports,
						id: row.userId,
						refresh: new Date().getTime(),
						columnId: this.$route.query.id,
						columnPid: this.$route.query.pid,
					}
				});
			}
		},
		sportEventList() {
			this.$api.sportEventList({ dictValue: '其他' }).then(res => {
				this.options1 = res.data.data.result
			})
		},
		judgmentLevel() {
			this.$api.judgmentLevel().then(res => {
				this.options2 = res.data.data.result
			})
		},
		areaList() {
			this.$api.areaList().then(res => {
				this.options3 = res.data.data.result
			})
		},
		applyfor() {
			if (this.$store.state.token == '') {
				this.$confirm('当前尚未登录，请登录', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$store.commit("EXIT_TOKEN")
					this.$router.push({
						path: '/login',
						query: {
							person: true,
							grp: false,
							refresh: new Date().getTime()
						}
					})
				}).catch(() => { });
				return
			}
			if (this.$store.state.userType == 'user_type_02') {
				this.$confirm('当前账号为团体账号，是否切换成个人账号', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$store.commit("EXIT_TOKEN")
					this.$router.push({
						path: '/login',
						query: {
							person: true,
							grp: false,
							refresh: new Date().getTime()
						}
					})
				}).catch(() => { });
				return
			}

			if (this.$store.state.realnameornot.caStatus == 0) { // 未人脸识别
				if (!this.$store.state.realnameornot.createTime) {
					this.$confirm('当前账号尚未身份验证，是否前往身份验证', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$router.push({
							path: '/registerIndex',
							query: {
								refresh: new Date().getTime(),
								menuIndex: 4,
							},
						})
					}).catch(() => { });
					return
				}
				if (this.$store.state.realnameornot.createTime && this.$store.state.realnameornot.requeststatus == 0) {
					this.$alert('当前账号身份验证审核中，请等待审核完成', '提示', {
						confirmButtonText: '确定',
						type: 'warning',
						callback: action => {
							this.$router.push({
								path: '/registerIndex',
								query: {
									refresh: new Date().getTime(),
									menuIndex: 4,
									nameornot: true
								},
							})
						}
					});
					return
				}
				if (this.$store.state.realnameornot.createTime && this.$store.state.realnameornot.requeststatus == 2) {
					this.$alert('当前账号身份验证未通过，请先完成身份验证', '提示', {
						confirmButtonText: '确定',
						type: 'warning',
						callback: action => {
							this.$router.push({
								path: '/registerIndex',
								query: {
									refresh: new Date().getTime(),
									menuIndex: 4,
									nameornot: true
								},
							})
						}
					});
					return
				}
				if (this.$store.state.realnameornot.createTime && this.$store.state.realnameornot.requeststatus == 1) {
					this.$router.push({
						path: '/registerIndex',
						query: {
							refresh: new Date().getTime(),
							menuIndex: 1,
							step: 2,
						},
					})
					return
				}
			} else { // 通过人脸识别
				if (this.$store.state.realnameornot.caStatus == 2) {
					this.$alert('当前账号人脸识别未通过，请重新认证', '提示', {
						confirmButtonText: '确定',
						type: 'warning',
						callback: action => {
							this.$router.push({
								path: '/registerIndex',
								query: {
									refresh: new Date().getTime(),
									menuIndex: 4,
									nameornot: true
								},
							})
						}
					});
				} else if (this.$store.state.realnameornot.requeststatus == 0 && this.$verification(this.$store.state.realnameornot.headPic)) {
					this.$alert('当前账号未上传免冠照，请上传', '提示', {
						confirmButtonText: '确定',
						type: 'warning',
						callback: action => {
							this.$router.push({
								path: '/registerIndex',
								query: {
									refresh: new Date().getTime(),
									menuIndex: 7,
									photoau: false
								},
							})
						}
					});
				} else if (this.$store.state.realnameornot.requeststatus == 0 && !this.$verification(this.$store.state.realnameornot.headPic)) {
					this.$alert('当前账号免冠照审核中，请等待审核完成', '提示', {
						confirmButtonText: '确定',
						type: 'warning',
						callback: action => {
							this.$router.push({
								path: '/registerIndex',
								query: {
									refresh: new Date().getTime(),
									menuIndex: 7,
									photoau: true
								},
							})
						}
					});
				} else if (this.$store.state.realnameornot.requeststatus == 2) {
					this.$alert('当前账号免冠照审核未通过，请重新上传', '提示', {
						confirmButtonText: '确定',
						type: 'warning',
						callback: action => {
							this.$router.push({
								path: '/registerIndex',
								query: {
									refresh: new Date().getTime(),
									menuIndex: 7,
									photoau: true
								},
							})
						}
					});
				} else if (this.$store.state.realnameornot.caStatus == 1 && this.$store.state.realnameornot.requeststatus == 1) {
					this.$router.push({
						path: '/registerIndex',
						query: {
							refresh: new Date().getTime(),
							menuIndex: 1,
							step: 2,
						},
					})
				}
			}

		},
		download() {
			const a = document.createElement('a')
			a.href = '/static/裁判员注册操作手册.pdf'
			a.download = '裁判员注册操作手册.pdf'
			a.click()
		},
		getnav(e) {
			this.navIndex = e
			this.reset()
			this.refreshNum = e
		},
		selectAllRefereeUser() {
			const infoCopy = JSON.parse(JSON.stringify(this.info));
			if (!this.$verification(infoCopy.sports)) {
				infoCopy.sports = infoCopy.sports.join(",")
			} else {
				infoCopy.sports = ''
			}
			if (!this.$verification(infoCopy.applysportsForStatus)) {
				infoCopy.applysportsForStatus = infoCopy.applysportsForStatus.join(",")
			} else {
				infoCopy.applysportsForStatus = ''
			}
			if (!this.$verification(infoCopy.refereeLevel)) {
				infoCopy.refereeLevel = infoCopy.refereeLevel.join(",")
			} else {
				infoCopy.refereeLevel = ''
			}
			if (!this.$verification(infoCopy.certificateArea)) {
				infoCopy.certificateArea = infoCopy.certificateArea.join(",")
			} else {
				infoCopy.certificateArea = ''
			}
			// if(!this.$verification(infoCopy.applyForStatus)){
			// 	infoCopy.applyForStatus = infoCopy.applyForStatus.join(",")
			// }else{
			// 	infoCopy.applyForStatus = ''
			// }
			if (!this.$verification(infoCopy.refereeSex)) {
				infoCopy.refereeSex = infoCopy.refereeSex.join(",")
			} else {
				infoCopy.refereeSex = ''
			}
			this.$api.selectAllRefereeUser(infoCopy).then(res => {
				res.data.data.result.records.forEach(item => {
					if (item.avatar) {
						this.$api.getFile({
							objectName: item.avatar
						}).then(ess => {
							this.$convert(ess.data.data.result.url).then((fileUrl) => {
								item.image = fileUrl
								this.$forceUpdate()
							})
						})
					} else {
						item.image = require('@/assets/image/defaults.png')
						this.$forceUpdate()
					}
				})
				res.data.data.result.records.forEach(item => {
					if (!this.$verification(item.refereeName)) {
						item.refereeName = this.$decrypt(item.refereeName || '')
					}
				})
				if (this.navIndex == 2) {
					this.data = res.data.data.result.records
					this.total = res.data.data.result.total
				}
			})
		},
		getdate(e) {
			if (this.$verification(e)) {
				this.info.startDate = ''
				this.info.endDate = ''
			} else {
				this.info.startDate = e[0]
				this.info.endDate = e[1]
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.notice {
	width: 1200PX;
	margin: 0 auto;
}

.header {
	display: flex;
	margin-top: 34PX;
	margin-left: 20PX;
	font-size: 18PX;
	font-weight: 500;
	color: #333333;
	cursor: pointer;
}

.current {
	color: #0066FF;
}

.buttom {
	display: flex;
	margin-right: 41PX;
	width: 160PX;
	height: 39PX;
	background: #D42C26;
	border-radius: 7PX;
	font-size: 15PX;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 39PX;
	margin-top: 25PX;
	cursor: pointer;
}

.buttom .img {
	width: 21PX;
	height: 21PX;
	margin-top: 10PX;
	margin-left: 28PX;
	margin-right: 10PX;
}

.buttomdow {
	text-decoration: underline;
	cursor: pointer;
	margin-top: 25PX;
	display: flex;
	align-items: center;
	color: #000;
}


.paging {
	margin-top: 21PX;
	margin-bottom: 133PX;
	display: flex;
	align-items: center;
}

.paging span {
	margin-right: 5PX;
}

::v-deep .el-pager li.active {
	color: #003399;
}

.searchfor {
	width: 1200PX;
	height: 123PX;
	border-radius: 7PX;
	margin-top: 29PX;
	display: flex;
	flex-wrap: wrap;
}

.searchfor ::v-deep .el-input__inner {
	width: 227PX;
	height: 43PX;
	background: #FFFFFF;
	border: 1PX solid #003680;
	border-radius: 7PX;
	font-size: 18PX;
	color: #333333;
	padding: 0 15PX 0 57PX;
}

.searchfor ::v-deep input::-webkit-input-placeholder {
	color: #333333;
}

.searchfor ::v-deep input::-moz-input-placeholder {
	color: #333333;
}

.searchfor ::v-deep input::-ms-input-placeholder {
	color: #333333;
}

.searchfor ::v-deep .el-select .el-input .el-select__caret {
	color: #191919;
}

.searchfor .searchforlogo {
	display: block;
	width: 28PX;
	height: 27PX;
	margin-top: 8PX;
	margin-left: 17PX;
}

.searchfor .searchforlogo img {
	width: 100%;
	height: 100%;
}

.name {
	margin-top: 41PX;
}

.project {
	margin-top: 41PX;
	margin-left: 26PX;
}

.level {
	margin-top: 41PX;
	margin-left: 26PX;
}

.area {
	margin-top: 41PX;
	margin-left: 26PX;
}

.status {
	margin-top: 19PX;
}

.year {
	margin-top: 19PX;
	margin-left: 26PX;
}

.but {
	width: 150PX;
	height: 43PX;
	background: #164B92;
	border-radius: 9PX;
	display: flex;
	align-items: center;
	margin-top: 41PX;
	margin-left: 26PX;
	cursor: pointer;
}

.but .img {
	width: 21PX;
	height: 21PX;
	margin-left: 31PX;
}

.but .text {
	font-size: 19PX;
	font-weight: bold;
	color: #FFFFFF;
	margin-left: 7PX;
	letter-spacing: 4PX;
}

.piece {
	width: 1200PX;
	margin-top: 72PX;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}


.backck {
	width: 368PX;
	height: 225PX;
	margin-bottom: 42PX;
	cursor: pointer;
	margin-right: 48PX;
}

.backck:nth-child(3n) {
	margin-right: 0;
}

.backck img {
	width: 100%;
	height: 100%;
}

.backck .information {
	display: flex;
	justify-content: space-between;
}

.backck .information .headsculpture {
	position: absolute;
	width: 131PX;
	height: 190PX;
	margin-top: 17PX;
	margin-left: 14PX;
}

.backck .information .headsculpture img {
	width: 100%;
	height: 100%;
	border-radius: 10PX;
}

.specific-box {
	position: absolute;
	margin-left: 164PX;
	min-width: 140PX;
}

.specific-box .specific {
	display: flex;

}

.specific-box .specific .identifying {
	width: 4PX;
	height: 21PX;
	background: #0046A3;
	margin-top: 31PX;
}

.specific-box .specific .definition {
	font-size: 20PX;
	font-weight: bold;
	color: #0046A3;
	margin-left: 12PX;
	margin-top: 28PX;
	width: 60%;
	white-space: nowrap; /* 确保文本在一行内显示 */
	overflow: hidden; /* 隐藏超出容器的文本 */
	text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
}

.specific-box .specific .garden {
	width: 24.6PX;
	height: 24.6PX;
	margin-top: 19PX;
	margin-left: -10PX;
}

.step {
	font-size: 15PX;
	font-weight: 400;
	color: #000;
}

.el-select-dropdown__item {
	font-size: 18PX;
}

.nav {
	display: flex;
	margin-top: 27PX;

	div {
		width: 304.3PX;
		height: 50PX;
		background: #E8F2FF;
		border-radius: 20PX 20PX 0PX 0PX;
		font-family: SourceHanSansCN;
		font-weight: bold;
		font-size: 23PX;
		color: #437DCA;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 16PX;
		cursor: pointer;
	}
}

.xian {
	width: 1185PX;
	height: 2PX;
	background: #85B0E9;
	border-radius: 1PX;
}

.select {
	background: linear-gradient(90deg, #6FB2FF, #095FFF) !important;
	box-shadow: 0PX 7PX 16PX 0PX rgba(50, 129, 255, 0.51);
	border-radius: 20PX 20PX 0PX 0PX;
	color: #ffffff !important;
}


::v-deep .el-range-input {
	font-size: 18PX;
}

::v-deep .el-date-editor .el-range-separator {
	padding: 0;
	font-size: 18PX;
	width: auto;
	margin-top: 5PX
}

::v-deep .year .el-range__icon {
	font-size: 29PX;
	width: auto;
	margin-left: 17PX;
	margin-top: 6PX;
	color: #4882e6;
}

::v-deep .year .el-range__close-icon {
	display: none;
}

::v-deep .el-select__tags {
	margin-left: 57PX;
}
</style>
